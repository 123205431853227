import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { observer } from 'mobx-react';
import Styles from './CloseDialog.module.scss';
import { CHAT_EVENT_TYPE } from '../../../const/ChatConst.js';

@observer
class CloseDialog extends Component {
  close() {
    let { store } = this.props;
    store?.setChannel(null);
  }

  render() {
    let { store, onCloseCallback } = this.props;
    return (
      <div className={Styles.BackDrop}>
        <div className={Styles.Dialog}>
          <div className={Styles.Title}>
            티켓{' '}
            {store.finishedType == CHAT_EVENT_TYPE.CLOSE
              ? '종료'
              : store.finishedType == CHAT_EVENT_TYPE.COMPLETE
                ? '종결'
                : '이관'}
          </div>
          <div className={Styles.Content}>
            상담중 티켓이{' '}
            {store.finishedType == CHAT_EVENT_TYPE.CLOSE
              ? '종료'
              : store.finishedType == CHAT_EVENT_TYPE.COMPLETE
                ? '종결'
                : '이관'}{' '}
            되었습니다.{`\n`}
            확인을 누르면 상담중 창이 닫힙니다.
          </div>
          <div className={Styles.ButtonGroup}>
            <Button
              variant={`outline-primary`}
              onClick={() => {
                onCloseCallback && onCloseCallback(store.channel);
              }}
            >
              확인
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export { CloseDialog };
