import React from 'react';
import Styles from './ChannelList.module.scss';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Channel } from './Channel.js';
import { NoChannel } from './NoChannel.js';
import Pagination from '../dataGrid/Pagination.js';

@observer
class ChannelList extends React.Component {
  componentDidMount() {
    // let {store} = this.props;
  }
  render() {
    let {
      store,
      onChannelClick,
      header,
      footer,
      isAgent = false,
      renderLabel,
      onCloseCallback,
      activeChannelId,
      isSummary,
      isStatus = true,
      noChannelText,
    } = this.props;
    return (
      <div className={Styles.Channel}>
        {header && header}
        <div className={Styles.Scroll}>
          {store.channels.length > 0 ? (
            store.channels.map((channel) => (
              <Channel
                key={channel.id}
                isSummary={isSummary}
                isStatus={isStatus}
                channel={channel}
                store={store}
                onChannelClick={onChannelClick}
                isAgent={isAgent}
                renderLabel={renderLabel}
                onCloseCallback={onCloseCallback}
                activeChannelId={activeChannelId}
              />
            ))
          ) : (
            <NoChannel text={noChannelText} />
          )}
        </div>

        {!store.isNoPaging && store.count > 0 && (
          <Pagination store={store} className={Styles.Pagination} />
        )}

        {footer}
      </div>
    );
  }
}

ChannelList.propType = {
  // store: PropTypes.instanceOf(ChannelStore),
  onChannelClick: PropTypes.func,
  header: PropTypes.element,
};

export { ChannelList };
