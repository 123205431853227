import React, { Component } from 'react';
import {
  channelStore,
  chatStore,
  quickMsgStore,
  rSocket,
  ticketStore,
} from '../store/FrontChatStore';
import { Button, FormControl } from 'react-bootstrap';
import axios from 'axios';
import Cookies from 'js-cookie';
import {
  COOKIE_KEY_USER_ID,
  COOKIE_KEY_USER_KEY,
  COOKIE_KEY_CHANNEL_ID,
  COOKIE_KEY_TEL_NO,
  COOKIE_KEY_SCENARIO_YN,
  COOKIE_KEY_SCENARIO_ID,
} from '../const/AppConst';
import { v4 as uuid } from 'uuid';
import Select from 'react-select';
import { ADJECTIVES, ANIMALS } from '@qbot-chat/qbot-uikit';
import { navigateReplace } from '../helper/HistoryHelper';
import { observer } from 'mobx-react';

@observer
class InitView extends Component {
  userKey = Cookies.get(COOKIE_KEY_USER_KEY);
  constructor(props) {
    super(props);
    this.state = {
      inflowType: 'ARS',
      // userId:'SbMRn5Y5w/g93xLLoM1ZJQ==',
      userKey: this.userKey,
      userId: Cookies.get(COOKIE_KEY_USER_ID),
      chatBotType: '',
      sid: '',
      options: [],
    };
  }

  componentDidMount() {
    if (!this.userKey || this.userKey == 'undefined') {
      this.userKey = uuid();
      Cookies.set(COOKIE_KEY_USER_KEY, this.userKey);
      this.setState({ userKey: this.userKey });
    }

    if (this.state.options.length == 0) {
      axios.defaults.baseURL = '/api/v1';
      axios
        .post('/company/companyLists', {
          status: '',
          keyword: '',
        })
        .then((res) => {
          console.log(res.data);
          let datas = res.data;
          for (let data of datas) {
            console.log(data);
            data.label = data.companyName;
            data.value = data.id;
          }
          this.setState({ options: res.data });
        });
    }

    //        if(this.state.options.length == 0){
    //            axios.defaults.baseURL = '/api/v1';
    //            axios.post("/company/list",{
    //              "status":"",
    //              "keyword":""
    //              }).then((res) => {
    //                    console.log(res.data);
    //                    let datas = res.data.items;
    //                    for(let data of datas){
    //                        console.log(data);
    //                        data.label = data.companyName;
    //                        data.value = data.id;
    //                    }
    //                    this.setState({options:res.data.items})
    //                })
    //        }
  }

  onCompanyChange = (e) => {
    this.setState({ chatBotType: e.companyTel });
  };

  callCounsle = async () => {
    if (this.state.chatBotType == '') {
      alert(
        '상담할 회사를 선택해주세요. 혹은 선택한 회사의 전화번호가 존재하지 않습니다.',
      );
      return false;
    }

    await rSocket.close();
    let user_key = Cookies.get(COOKIE_KEY_USER_KEY);

    const { data: customer } = await rSocket.requestChatApi(`/user/search`, {
      key: user_key,
      name: `${ADJECTIVES[Math.floor(Math.random() * ADJECTIVES.length)]} ${
        ANIMALS[Math.floor(Math.random() * ANIMALS.length)]
      }`,
    });

    console.log(customer);

    Cookies.set(COOKIE_KEY_USER_ID, customer.id);
    if (customer.id) {
      this.setState({ userId: customer.id });
    }
    //Cookies.set(COOKIE_KEY_USER_KEY, customer.key);
    await rSocket.run(
      {
        sender: customer,
        ticket: ticketStore,
        channel: channelStore,
        chat: chatStore,
      },
      (rsocket) => {
        quickMsgStore.setRSocketStore(rSocket);
        chatStore.setQuickMsgStore(quickMsgStore);
      },
    );

    axios.defaults.baseURL = '/mp/api/v1';
    let params = { ...this.state };
    console.log(params);
    delete params['options'];
    console.log('params : {}:', params);

    Cookies.set(COOKIE_KEY_TEL_NO, this.state.chatBotType);

    //시나리오가 있는 음성봇이거나 챗봇이면 시나리오대로 타도록 수정
    Cookies.set(COOKIE_KEY_SCENARIO_YN, 'Y');

    Cookies.set(COOKIE_KEY_SCENARIO_ID, this.state.sid);

    axios.post('/channel-log/append', params).then((res) => {
      Cookies.set(COOKIE_KEY_CHANNEL_ID, res.data.channelId);
      navigateReplace('/loading/false');
      //                window.location.href=`/?user_id=${this.userKey}&chnlId=${res.data.channelId}&telNo=${this.state.chatBotType}`
    });
  };

  render() {
    return (
      <div className={`d-flex flex-default overflow-hidden`}>
        <div>
          <FormControl
            disabled={true}
            value={this.state.inflowType}
            onChange={(e) => this.setState({ inflowType: e.target.value })}
          />
          <FormControl
            disabled={true}
            value={this.userKey}
            onChange={(e) => this.setState({ userId: e.target.value })}
          />
          <FormControl
            value={this.state.chatBotType}
            onChange={(e) => this.setState({ chatBotType: e.target.value })}
          />
          <FormControl
            value={this.state.sid}
            onChange={(e) => this.setState({ sid: e.target.value })}
          />
          <Select
            options={this.state.options}
            onChange={this.onCompanyChange}
          />
          <Button onClick={this.callCounsle}>상담 시작</Button>
        </div>
      </div>
    );
  }
}

export default InitView;
