import React from 'react';
import Styles from './Channel.module.scss';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { ChatStore } from '../../store/ChatStore.js';
import {
  CHAT_EVENT_TYPE,
  TICKET_STATUS,
  TICKET_CLOSE_STATUS,
} from '../../const/ChatConst.js';
import ContractionMessage from '../chat/message/ContractionMessage.js';
import ContractionMessageErrorBoundary from '../chat/message/ContractionMessageErrorBoundary.js';
import dayjs from 'dayjs';
import { ThreeDots } from 'react-loader-spinner';
import { formatPhoneNumber, handleCopyClipBoard } from '../../utils/common.js';
import { Button, Tag, Tooltip } from 'antd';
import { CloseOutlined, CopyOutlined } from '@ant-design/icons';
import { ChannelStatus } from './ChannelStatus.js';

@observer
class Channel extends React.Component {
  render() {
    let {
      store,
      channel,
      onChannelClick,
      // location,
      isAgent = false,
      renderLabel,
      onCloseCallback,
      activeChannelId,
      isSummary,
      isStatus,
      isTypingUser,
    } = this.props;
    let {
      id,
      // createdAt,
      lastMessage,
      isConfirm = true,
      notConfirmCount = 0,
      isFinished = false,
      finishedType,
      assignment,
      keywords,
      summary,
      status,
      record,
      // customer,
    } = channel;

    const customerName = formatPhoneNumber(
      channel.customer.name,
      channel.customer.name ?? '',
    );

    return (
      <div className={`position-relative`}>
        <div
          className={`${Styles.ChannelBox} ${!isConfirm && isAgent ? Styles.On : ''} ${id === activeChannelId ? Styles.Active : ''}`}
          onClick={() => {
            if (activeChannelId === id) return;

            if (!TICKET_CLOSE_STATUS.includes(status)) {
              store.enterChannel(channel).then(() => {
                onChannelClick && onChannelClick(channel);
              });
            } else {
              onChannelClick && onChannelClick(channel);
            }
          }}
          onContextMenu={(e) => {
            e.preventDefault();
            store.openDropDown(channel, e.pageX, e.pageY);
          }}
        >
          <div className={Styles.ChannelRoom}>
            <div className={Styles.RoomDec}>
              {isStatus && (
                <ChannelStatus className={Styles.Tag} status={status} />
              )}
              {assignment?.reason && (
                <Tooltip placement="bottom" title={assignment?.reason}>
                  <Tag className={Styles.Tag} color="var(--qt-ch-warning)">
                    이관
                  </Tag>
                </Tooltip>
              )}
            </div>

            <div className={Styles.RoomInfo}>
              {isTypingUser && (
                <ThreeDots
                  height="14"
                  width="14"
                  radius="9"
                  color="var(--qt-primary6)"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              )}
              <span className={Styles.RoomCreatedAt}>
                {/* {dayjs(lastMessage?.createdAt).isBefore(dayjs()) ? dayjs(lastMessage?.createdAt).fromNow() : '몇 초 전'} */}
                {/* {dayjs(lastMessage?.createdAt).format(
                  status === TICKET_STATUS.ACTIVE
                    ? 'HH:mm'
                    : 'MM-DD HH:mm',
                )} */}

                {status === TICKET_STATUS.ACTIVE
                  ? dayjs(lastMessage?.createdAt).isBefore(dayjs())
                    ? dayjs(lastMessage?.createdAt).fromNow()
                    : '몇 초 전'
                  : dayjs(record?.endTime ?? lastMessage?.createdAt).format(
                      'MM-DD HH:mm',
                    )}
              </span>
            </div>
          </div>

          <div className={Styles.ChannelId}>
            <div className={Styles.TicketId}>
              {isSummary && summary ? summary : customerName}
            </div>
            <Button
              className={Styles.CopyButton}
              icon={<CopyOutlined />}
              size="small"
              type="text"
              onClick={(e) => {
                e.stopPropagation();
                handleCopyClipBoard(channel.id);
              }}
              tabIndex={-1}
            />
          </div>

          {TICKET_CLOSE_STATUS.includes(status) && keywords && (
            <div className={Styles.Message}>
              {keywords.map((v) => '#' + v).join(', ')}
            </div>
          )}

          {!TICKET_CLOSE_STATUS.includes(status) && lastMessage && (
            <div className={Styles.ChannelDec}>
              <div className={Styles.Message}>
                <ContractionMessageErrorBoundary>
                  <ContractionMessage message={lastMessage} />
                </ContractionMessageErrorBoundary>
              </div>
              {notConfirmCount > 0 && (
                <div className={`${Styles.NewMessageNum}`}>
                  {notConfirmCount > 9 ? '9+' : notConfirmCount}
                </div>
              )}
            </div>
          )}
        </div>
        {isFinished && (
          <div className={Styles.BackDrop}>
            <div className={Styles.CloseDialog}>
              <div className={Styles.Title}>
                {finishedType == CHAT_EVENT_TYPE.CLOSE
                  ? '종료'
                  : finishedType == CHAT_EVENT_TYPE.COMPLETE
                    ? '종결'
                    : '이관'}
                된 티켓
                <div className={Styles.Content}>{customerName}</div>
              </div>
              <Button
                className={Styles.CloseButton}
                icon={<CloseOutlined />}
                size="small"
                color="default"
                variant="filled"
                shape="circle"
                tabIndex={-1}
                onClick={() => {
                  store.deleteTicket(id);
                  onCloseCallback && onCloseCallback(channel);
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

Channel.propType = {
  store: PropTypes.instanceOf(ChatStore),
};

export { Channel };
