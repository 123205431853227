import { computed, makeObservable, observable, values, toJS } from 'mobx';

import PaginationStore from './PaginationStore.js';

class ChannelStore extends PaginationStore {
  constructor({ paginationSize = 10, rowsPerPage = 10, isNoPaging, sort }) {
    super();
    makeObservable(this);
    let store = this;
    store.paginationSize = paginationSize;
    store.rowsPerPage = rowsPerPage;
    store.isNoPaging = isNoPaging;
    store.sort = sort;
  }

  load = async () => {
    let store = this;
    const r = await store.rsocketStore.requestChatApi(
      `/ticket/list`,
      this.searchConditionWithPage,
    );

    let { items: channels, count } = r.data;
    this.count = count;
    store.channelMap.replace(channels.map((channel) => [channel.id, channel]));
  };

  setRSocketStore(rsocketStore) {
    this.rsocketStore = rsocketStore;
  }

  rsocketStore;

  @computed
  get sender() {
    return this.rsocketStore?.sender;
  }

  @observable
  channelMap = new Map();

  @computed
  get channels() {
    return toJS(values(this.channelMap)).sort(
      (a, b) => b.createdAt - a.createdAt,
    );
  }

  @computed
  get filteredChannels() {
    return (statuses) =>
      this.channels.filter((channel) => statuses.includes(channel.status));
  }

  changePage(page) {
    this.page = page;
    this.load();
  }

  refresh() {
    this.page = 1;
    this.load();
  }

  clear() {
    let store = this;
    store.channelMap.clear();
  }
}

export { ChannelStore };
