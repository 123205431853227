import React from 'react';
import { TICKET_STATUS } from '../../const/ChatConst';
import { Tag } from 'antd';

export const ChannelStatus = ({ status, ...props }) => {
  if (status === TICKET_STATUS.COMPLETE)
    return (
      <Tag
        style={{
          color: 'var(--qt-gray2)',
          background: 'var(--qt-gray6)',
        }}
        bordered={false}
        {...props}
      >
        종결
      </Tag>
    );
  if (status === TICKET_STATUS.AFTERCALL)
    return (
      <Tag color="gold" {...props}>
        후처리
      </Tag>
    );
  if (status === TICKET_STATUS.CLOSED)
    return (
      <Tag color="green" {...props}>
        종료
      </Tag>
    );

  if (status === TICKET_STATUS.IDLE)
    return (
      <Tag color="orange" {...props}>
        유휴
      </Tag>
    );
  if (status === TICKET_STATUS.ACTIVE)
    return (
      <Tag color="processing" {...props}>
        진행중
      </Tag>
    );

  return <></>;
};
