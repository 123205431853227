import { Chat } from './ui/chat/Chat';
import { ChatStore } from './store/ChatStore';
import * as ChatConst from './const/ChatConst';
import * as IconConst from './const/IconConst.js';
import { ChannelStore } from './store/ChannelStore';
import { TicketStore } from './store/TicketStore';
import { QuickMsgStore } from './store/QuickMsgStore';
import { RSocket } from './store/RsocketStore';
import { ChannelList } from './ui/channel/ChannelList';
import { TicketList } from './ui/channel/TicketList';
import { ChannelDropDown } from './ui/channel/ChannelDropDown';
import Sort from './store/Sort';
import { ScrollTabContainer } from './ui/tab/ScrollTabContainer';
import { ScrollTabNav } from './ui/tab/ScrollTabNav';
import { ScrollTabNavItem } from './ui/tab/ScrollTabNavItem';
import { ScrollTabContent } from './ui/tab/ScrollTabContent';
import { ScrollTabPanel } from './ui/tab/ScrollTabPanel';
import { Close } from './ui/chat/Close';
import { Rating } from './ui/chat/Rating';
import { Channel } from './ui/channel/Channel.js';
import { NoChannel } from './ui/channel/NoChannel.js';
import Message from './ui/chat/message/Message.js';
import MessageContent from './ui/chat/message/MessageContent.js';
import TeamDropdown from './ui/team/TeamDropdown.js';
import DropDownButton from './ui/chat/dropdown/DropDownButton.js';

const {
  CONTENT_TYPE,
  TICKET_STATUS,
  TICKET_CLOSE_STATUS,
  ANIMALS,
  ADJECTIVES,
  BUTTON_TYPE,
  MESSAGE_TYPE,
  SYSTEM_MESSAGE_TYPE,
  ADMIN_MESSAGE_TYPE,
  CHANNEL_STATUS,
  TICKET_EVENT_TYPE,
  TICKET_PROPERTY_TYPE,
  BUTTON_GROUP_TYPE,
  NOTIFICATION_TYPE,
  COMMAND_EVENT_TYPE,
  AVATAR_TYPE,
  USER_TYPE,
} = ChatConst;

const { ICON_TYPE } = IconConst;

export {
  Chat,
  ChatStore,
  ChannelStore,
  RSocket,
  ChannelList,
  QuickMsgStore,
  ScrollTabContainer,
  ScrollTabNav,
  ScrollTabNavItem,
  ScrollTabContent,
  ScrollTabPanel,
  ChannelDropDown,
  Sort,
  TicketStore,
  TicketList,
  Close,
  Channel,
  NoChannel,
  Rating,
  CONTENT_TYPE,
  TICKET_STATUS,
  TICKET_CLOSE_STATUS,
  ANIMALS,
  ADJECTIVES,
  BUTTON_TYPE,
  MESSAGE_TYPE,
  SYSTEM_MESSAGE_TYPE,
  ADMIN_MESSAGE_TYPE,
  CHANNEL_STATUS,
  TICKET_EVENT_TYPE,
  TICKET_PROPERTY_TYPE,
  ICON_TYPE,
  BUTTON_GROUP_TYPE,
  NOTIFICATION_TYPE,
  Message,
  MessageContent,
  COMMAND_EVENT_TYPE,
  TeamDropdown,
  DropDownButton,
  USER_TYPE,
  AVATAR_TYPE,
};
